
@import "./variables";

/* bootstrap variables */
@import "node_modules/bootstrap/scss/bootstrap.scss";

/* toasty */
@import "node_modules/ngx-toasty/style-bootstrap.css";

$hover-color: $light;
$selected-color: lighten($info, 50%); 
$border-color: darken($light, 20%);

@import "node_modules/primeicons/primeicons.css";
@import "primeng/resources/themes/saga-blue/theme.css";
@import "node_modules/primeng/resources/primeng.min.css";


/* jsoneditor */
@import "node_modules/jsoneditor/dist/jsoneditor.min.css";

/* virtual keyboard */
@import "node_modules/simple-keyboard/build/css/index.css";

/* tags */
@import "./tagify.css";

/* hack fix for z index overlay bug */
// https://github.com/ng-bootstrap/ng-bootstrap/issues/2686
.modal-backdrop {
    z-index: 1049!important;
  }
.modal-fit {
  width:fit-content;
  max-width: fit-content!important;
  .modal-content {
    width: inherit;
  }
}
//I like a cleaner header
.card-header {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: rgba(0, 0, 0, 0.01);

}

.ouline-focus {
  border-color: $input-focus-border-color;
}

.form-control:focus {
  box-shadow: none !important;
}

// .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
//   border: none;
//   border-bottom: 3px solid black;
//   border-color: black;
// }

.fn-small {
  font-size: small;
}

// .system-btn {
//   @extend btn;
//   @extend ;
//   @extend py-1;
//   @extend text-start text-nowrap;
// }

label {
  margin-bottom: 0.1rem;
  // font-size: small;
}

.accordion-button {
  background: transparent!important;
  padding: 0.25rem;
  box-shadow: none;
}




tr {
  font-weight: 300 !important; 
  box-shadow: none;
}

.p-component {
  font-size: 1.0rem;
}
.p-checkbox .p-checkbox-box{
  width: 16px !important;
  height: 16px !important;
}
.pi {
 font-size: 0.7rem;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
 margin-left: 0.2rem !important;
}
.pi-sort-alt {
  visibility:hidden !important;
} 

.p-datatable-thead  th {
 
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 0.75rem 0.75rem;
  border: 1px solid #f3f2f1;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #323130;
  /* background: #ffffff; */
  transition: box-shadow 0.2s;

  border-bottom: 1px solid #bdc6d0;
  // background-color: #f5f7f7;
  background-color: transparent;
  color: rgba(0,0,0,.54);
  vertical-align: top;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: none;
  /* box-shadow: inset 0 0 0 0.15rem #605e5c; */
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #323130;
  transition: box-shadow 0.2s;
}

.p-datatable-responsive-scroll>.p-datatable-wrapper>table, .p-datatable-auto-layout>.p-datatable-wrapper>table {
  border: 1px solid #bdc6d0 !important;
}

.p-datatable .p-paginator-bottom {
  border-top: 1px solid #bdc6d0; 
}

.p-datatable .p-datatable-tbody > tr:focus {
   outline: none;
 // outline-offset: 0.15rem;
}

.p-picklist .p-picklist-buttons .p-button {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}
.p-button {
  //
}
.bulk-edit-multiple {
    background-color: $gray-300 !important;
}